.dt-col-1,
.dt-col-2 {
  position: -webkit-sticky !important;
  position: sticky !important;
  left: 0 !important;
  z-index: 1 !important;
  background: white !important;
}

.ag-cell {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.ag-cell.button {
  padding: 0 !important;
  width: auto;
}

.ag-cell.no-padding {
  padding: 0 !important;
  width: auto !important;
}

/* .ag-column-first {
  width: auto !important;
  padding: 1rem !important;
} */
.ag-column-first.hide,
.ag-cell.hide {
  visibility: hidden !important;
}

.ag-cell.checkbox {
  top: 0 !important;

  .ag-selection-checkbox {
    margin-right: 0 !important;
  }
}
