.rentalYieldContainer {
  .tableContainer {
    .tableGroupContainer {
      > :nth-child(even) {
        justify-self: end;
      }

      @apply grid gap-y-2 mt-6;
      grid-template-columns: 1fr auto;

      label {
        @apply text-lg text-gray-600;

        span:last-of-type {
          @apply opacity-30 text-sm font-normal;
        }
      }
    }
  }
}
