.popupForm .formStyle {
    label {

        span {
            @apply text-sm;
        }

        input,
        textarea,
        select {
            @apply h-[30px] pl-2 text-sm;
        }

        textarea {
            height: 100px;
        }
    }
}

.formStyle {
    label {
        @apply flex flex-col gap-1;

        span {
            @apply min-w-20 pl-2 text-gray-400 font-medium;
        }

        input,
        textarea,
        select {
            @apply input border border-gray-300 bg-transparent;
        }

        textarea {
            height: 300px;
        }
    }
}