.contactInputs {
    >label {
        @apply flex flex-col xs:flex-row gap-2 items-start xs:items-center bg-white;

        >span {
            @apply min-w-40;
        }

        >input {
            @apply w-full;
        }
    }
}

.nameInputs {
    label {
        @apply flex flex-col items-start relative;

        >span {
            @apply text-sm absolute left-2 -top-2 text-gray-400 font-medium bg-white px-2;
        }

        >input {
            @apply input input-bordered bg-white;
        }

        >select {
            @apply select select-bordered bg-white;
        }
    }
}

.progressContainer {
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    height: 0.5rem; // Adjust the height as needed
}

.progressBar {
    height: 100%;
    background-color: #d05a52;
    width: 0;
    animation: simpleProgress 2s linear infinite;
}

@keyframes simpleProgress {
    0% {
        width: 0;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0;
    }
}