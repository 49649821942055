.mapboxgl-popup-content {
    @apply bg-transparent p-0;

    button {
        @apply text-white text-4xl font-light right-4 top-2 hover:bg-transparent hover:font-normal;
    }

}

.mapboxgl-ctrl-bottom-right {
    z-index: 0 !important;
}

.mapboxgl-popup {
    width: 400px !important;
}

#map.show {
    .marker-remove {
        display: flex;
    }
}

#map.hide {
    .marker-remove {
        display: none;
    }
}