.grid_view_development {
  display: inline-block !important;
  -webkit-box-shadow: transparent 0 0 0 1px, rgba(18, 18, 18, 0.1) 0 6px 16px;
  box-shadow: transparent 0 0 0 1px, rgba(18, 18, 18, 0.1) 0 6px 16px;
  border-radius: 10px;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  height: 100%;
  width: 100%;
  position: relative;
}

.grid_view_development b {
  font-weight: 600;
  @apply text-secondary-dark;
}

.house-icon {
  width: 25px;
}

// .grid_view_development:hover {
//   -webkit-transform: scale(1.01);
//   transform: scale(1.01);
//   -webkit-box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.168) 0 8px 25px;
//   box-shadow: transparent 0 0 0 3px, rgba(18, 18, 18, 0.168) 0 8px 25px;
// }




.flex-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.with_img {
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  height: 100%;

  &.mobile {
    grid-template-columns: auto 1fr;
    width: 90vw;
  }
}


.grid-dev-img {
  width: 100%;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}