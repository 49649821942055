.filterContainer {


  input {
    @apply border-gray-400 border-[0.5px];
    bar: foo;
  }
}

.filterHeader {
  @apply font-bold font-main-sans text-secondary-dark text-base border-b pt-4 mb-4 pb-2;
  // @apply font-bold font-main-sans text-[#aca6af] text-lg border-b pt-4 mb-4 pb-2;

  &.special {
    @apply mt-6;
  }
}

.inputWithCornerLabel {
  @apply relative;

  >span {
    @apply absolute left-3 -top-[0.6rem] bg-white px-2 text-sm font-light text-gray-400;
  }

  input {
    @apply bg-white border-gray-400;
  }
}

.checkboxesContainer {
  @apply grid grid-cols-3 gap-2 justify-between w-full;

  label {
    @apply flex gap-2 items-center text-sm;

    input {
      @apply checkbox border border-gray-300;
    }
  }
}

.checkboxesContainer.twoGrid {
  @apply grid-cols-2 justify-between w-full;
}

.btnOutline {
  border: 0.5px solid hsl(0, 0%, 85%);

  &.thicker {
    @apply border-secondary-dark;
    border-width: 1px;

    &:hover {
      outline: 1px solid var(--secondary-dark);
      border: 0.5px solid var(--secondary-dark);
    }
  }

  &:hover {
    outline: 1px solid hsl(0, 0%, 85%);
    border: 0.5px solid hsl(0, 0%, 45%);
    background-color: transparent;
    color: var(--secondary-dark);
  }
}

.actionButtons {
  grid-template-columns: 1fr auto;
}