.gridContainer {
  @apply p-[2.5vw] mobile:p-10 grid gap-8 bg-white;
  grid-template-columns: repeat(3, calc((100% - 4rem) / 3));
  /* height: -webkit-fit-content; */
  /* height: fit-content; */
  overflow: visible;
  height: auto;
}

.gridContainer.withFilter {
  grid-template-columns: repeat(2, calc((100% - 2rem) / 2));
}

@media screen and (max-width: 1600px) {
  .gridContainer {
    grid-template-columns: repeat(2, calc((100% - 2rem) / 2));
  }

  .gridContainer.withFilter {
    grid-template-columns: repeat(2, calc((100% - 2rem) / 2));
  }
}
@media screen and (max-width: 1300px) {
  .gridContainer.withFilter {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1070px) {
  .gridContainer {
    grid-template-columns: repeat(1, 1fr);
  }

  .gridContainer.withFilter {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* MOBILE  */
@media screen and (max-width: 420px) {
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    height: -webkit-fit-content;
    height: fit-content;
    gap: 3rem;
    padding: 20px;
    margin: 0px 0 4rem 0;
  }
}

@media screen and (max-width: 375px) {
  .grid_view_development {
    .text-container {
      h2 {
        font-size: 20px;
      }
    }

    .with_text {
      grid-template-columns: 100%;
    }
  }
}
