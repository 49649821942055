.itemsGrid {
    @apply grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-12;
}

.itemContainer {
    @apply flex gap-6 items-start;

    >div:first-child {
        @apply bg-secondary-light/20 inline-block p-2 rounded-lg;
    }

    >div>h4 {
        @apply text-xl font-medium mb-2;
    }
}



@media screen and (max-width:600px) {
    .home {
        height: auto;
    }
}