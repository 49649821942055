.headerContainer:has(.expandableHeader:hover) .slogen {
  @apply block;
}



@media screen and (min-width: 568px) {
  .headerContainer:has(.expandableHeader:hover) {
    @apply border-b border-gray-100 shadow-lg shadow-gray-600/5;
  }

  .expandableHeader .expandedContainer {
    @apply hidden;
  }

  .expandableHeader:hover .expandedContainer {
    @apply h-auto flex flex-col gap-6;
  }

  .expandableHeader .expandedContainer:hover {
    @apply h-auto flex;
  }
}

.expandableHeader .expandedContainer>a:hover {
  @apply text-main;

  svg {
    filter: invert(21%) sepia(71%) saturate(2778%) hue-rotate(261deg) brightness(60%) contrast(91%);
  }
}

.expandableHeader:hover>p,
.expandableHeader:hover>a>p {
  @apply text-main;
}

@media (max-width: 820px) {
  .top {
    padding-bottom: 0px;

    .scroll {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      white-space: nowrap;
      overflow: scroll;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

    }
  }


  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}