@tailwind components;
@tailwind utilities;

@layer components {
  #to-print-mortgage.full,
  #to-print-mortgage.full > form,
  #to-print-mortgage.full .bar-container,
  #to-print-mortgage.full #mortgage-results,
  #to-print-mortgage.full #mortgage-show-breakdown,
  #to-print-mortgage.full .mortgage-result-table,
  #to-print-mortgage.full .label .input-container,
  #to-print-mortgage.full .label .input-container input {
    @apply w-full;
  }

  #to-print-mortgage.tool {
    width: 100%;

    label {
      @apply sm:items-center;
    }
  }

  #to-print-mortgage .bar-container {
    @apply w-[250px] md:w-[300px];
    bar: foo;
  }

  #to-print-mortgage {
    #mortgage-show-breakdown,
    .mortgage-result-table {
      @apply w-[250px];
      bar: foo;
    }

    .label {
      @apply flex justify-between w-full items-start gap-2 flex-col sm:flex-row relative;

      > span {
        @apply font-semibold;
      }

      .input-container {
        @apply input input-bordered border-tertiary-light bg-transparent flex items-center;

        input {
          @apply w-[150px] bg-white;
        }
      }

      .left {
        @apply absolute left-5;
      }

      .right {
        @apply absolute right-10;
      }
    }
  }

  .sm\:items-center {
    @apply items-center;
  }

  .sm\:flex-row {
    @apply flex-row;
  }

  .sm\:w-full {
    @apply w-full;
  }

  .sm\:max-w-xs {
    @apply max-w-xs;
  }
}
