.station-name,
.dev-names {
  font-family: sans-serif;
  font-weight: 400;
  font-size: 0.8em;
}

.station-name {
  fill: black;
  /* fill: rgb(73, 93, 121); */
  /* fill: #3f0d6c; */
  /* fill: #c8bdd1; */
  /* fill: #d2b6ae; */
  font-weight: 600;
  /* fill:#e4d5d1; */
}
