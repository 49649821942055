@import url("https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@300;500&family=Oxygen+Mono&display=swap");

#euston-square2 {
  cursor: pointer;
  fill: red;
}
/* 
  .text-to-transform {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background-color: blue;
    transform: matrix(1 0 0 1 510.0743 384.9251);
  } */

#svg-container {
  position: relative;
}

#dev-svg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 30px !important;
  font-family: "Mukta Vaani", sans-serif;
  /* font-family: "Oxygen Mono", monospace; */
}

#tube-svg {
  font-size: 30px;
  font-family: "Mukta Vaani", sans-serif;
  /* font-family: "Oxygen Mono", monospace; */
}

.svgview-container {
  /* height: calc(100vh - 60px); */
  /* overflow: hidden; */
  width: 100%;
  /* width: 100vw; */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.svgview-container .transform-component-module_wrapper__7HFJe {
  overflow: visible;
}

.svgview-zoom-button-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 1000;
}

.svgview-zoom-button-container > button,
.svgview-zoom-button-container > div {
  -webkit-box-shadow: 0 0 10px rgb(18, 32, 52, 0.3);
  box-shadow: 0 0 10px rgb(18, 32, 52, 0.3);
  border-radius: 5px;
}
.svgview-zoom-button-restore {
  opacity: 0.9;
}
.svgview-zoom-button-in {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.svgview-zoom-button-out {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.svgview-zoom-button-container > div {
  background-color: rgb(24, 5, 40, 0.5);
  /* background-color: rgba(19, 25, 37, 0.5); */
}

.svgview-zoom-button {
  border: none;
  margin: 8px;
  background-color: transparent;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.disable.svgview-zoom-button {
  opacity: 0.5;
}
.disable.svgview-zoom-button:hover {
  cursor: not-allowed;
}

.svgview-zoom-button-out {
  padding-top: 8px;
}

.svgview-zoom-button > img {
  width: 20px;
  height: 20px;
}

.svgview-zoom-button:hover {
  cursor: pointer;
}

@media screen and (max-width: 920px) {
  .svgview-container {
    /* padding-top: 38px;
    width: 100vw; */
  }
}

/* @media screen and (max-width: 650px) { */
.react-transform-wrapper {
  overflow: visible !important;
}
/* } */

@media screen and (max-width: 420px) {
  #euston-square2 {
    cursor: pointer;
    fill: red;
  }

  /* .text-to-transform {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    background-color: blue;
    transform: matrix(1 0 0 1 510.0743 384.9251);
  } */

  #svg-container {
    position: relative;
    margin-top: 55px;
  }

  .svgview-container {
    height: calc(100vh - 207.13px);
    overflow: hidden;
    /* overflow: scroll; */
    width: 100vw;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    /* padding-top: 70px; */
  }

  .home-page-container .svgview-container {
    height: auto;
    width: auto;
  }

  #dev-svg {
    /* z-index: 1000; */
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 30px !important;
    font-family: "Mukta Vaani", sans-serif;
    /* font-family: "Oxygen Mono", monospace; */
  }

  #tube-svg {
    font-size: 30px;
    font-family: "Mukta Vaani", sans-serif;
    /* font-family: "Oxygen Mono", monospace; */
  }

  .svgview-zoom-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    top: 20px;
    left: 20px;
    right: auto;
    z-index: 10;
  }

  .svgview-zoom-button-container > button,
  .svgview-zoom-button-container > div {
    -webkit-box-shadow: 0 0 10px rgb(18, 32, 52, 0.3);
    box-shadow: 0 0 10px rgb(18, 32, 52, 0.3);
    border-radius: 5px;
  }
  .svgview-zoom-button-restore {
    opacity: 0.9;
  }
  .svgview-zoom-button-in {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .svgview-zoom-button-out {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .svgview-zoom-button-container > div {
    background-color: rgba(19, 25, 37, 0.5);
  }

  .svgview-zoom-button {
    border: none;
    background-color: transparent;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .disable.svgview-zoom-button {
    opacity: 0.5;
  }
  .disable.svgview-zoom-button:hover {
    cursor: not-allowed;
  }

  .svgview-zoom-button-out {
    padding-top: 8px;
  }

  .svgview-zoom-button > img {
    margin: 0px;
    width: 20px;
    height: 20px;
  }

  .svgview-zoom-button:hover {
    cursor: pointer;
  }
  .react-transform-wrapper {
    overflow: visible !important;
  }
}
