@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Nothing You Could Do";
  src: url("./assets/fonts/NothingYouCouldDo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Zhi Mang Xing";
  src: url("./assets/fonts/ZhiMangXing-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Whisper";
  src: url("./assets/fonts/Whisper-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  color: rgb(68, 68, 68) !important;
  color: black !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    background-color: white !important;
    color: rgb(68, 68, 68) !important;
  }
}

.input:focus,
.input:focus-within {
  outline: none !important;
}
/* 
html, body {
  overscroll-behavior-y: contain;
    touch-action: none;
} */

/* =========================================================== */
/* =========================================================== */
/* =========================================================== */
.driver-popover.driverjs-theme {
  background-color: #f7df72;
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #000;
  color: #ffffff;
  border: 2px solid #000;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #000;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
  margin-left: 16px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 18px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #000;
  background-color: transparent;
  border: none;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #f7df72;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #f7df72;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #f7df72;
}

.driver-popover.driverjs-theme
  .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #f7df72;
}

.react-transform-wrapper,
.react-transform-component {
  width: auto !important;
}

.home-page-container .react-transform-wrapper,
.home-page-container .react-transform-component {
  @apply rounded-xl;
}

/*  */
/* #print-list-view .info-dev-content > div:last-of-type {
  -webkit-flex-direction: row !important;
  flex-direction: row !important;
  gap: 1.75rem !important;
}

#print-list-view .info-dev-content > div:last-of-type .price-list {
  display: -webkit-flex;
  display: flex;
  gap: 0.25rem !important;
  width: 40mm !important;
  max-width: 40mm !important;
} */




