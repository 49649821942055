.partners {
  position: relative;
  @apply px-10 xs:px-20 sm:px-32 py-20 mb-40;
  /* padding: 3rem 0 4rem; */
  /* background-color: #f3f3f3; */
  /* background-color: white; */
  /* background-color: var(--darkest-blue); */
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 80px;
}

.partners > div {
  margin: 0 auto;
  padding: 0rem 0rem;
}

.partners .slider-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* overflow: hidden; */
}

.partners .slider {
  overflow: hidden;
}

.partners .slider-list {
  width: 80vw;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.partners .slide {
  width: calc(80vw / 11 - 40px);
  /* width: calc(60vw / 11 - 40px); */
  /* width: 100px; */
  margin: 0 20px;
  opacity: 65%;
  filter: grayscale(1);
  /* transform: translateX(calc(-80vw / 5)); */
}

.slider-arrow {
  width: 30px;
  height: 30px;
}
.slider-arrow:hover {
  cursor: pointer;
}
/* } */

@media screen and (min-width: 1800px) {
  .partners .slide {
    width: calc(80vw / 11 - 40px);
  }
}
/* @media screen and (max-width: 1800px) { */
@media screen and (min-width: 1700px) and (max-width: 1800px) {
  .partners .slide {
    width: calc(80vw / 10 - 40px);
  }
}
@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .partners .slide {
    width: calc(80vw / 9 - 40px);
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .partners .slide {
    width: calc(80vw / 8 - 40px);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .partners .slider-list {
    width: 70vw;
  }
  .partners .slide {
    width: calc(70vw / 7 - 40px);
  }
}
@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .partners .slider-list {
    width: 70vw;
  }
  .partners .slide {
    width: calc(70vw / 6 - 40px);
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .partners .slider-list {
    width: 60vw;
  }
  .partners .slide {
    width: calc(60vw / 5 - 40px);
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .partners .slider-list {
    width: 60vw;
  }
  .partners .slide {
    width: calc(60vw / 4 - 40px);
  }
}
@media screen and (min-width: 430px) and (max-width: 600px) {
  .partners .slider-list {
    width: 60vw;
  }
  .partners .slide {
    width: calc(60vw / 3 - 40px);
  }
}
@media screen and (max-width: 430px) {
  .partners .slide {
    /* width: calc(80vw / 1 - 40px); */
    width: 50vw;
  }
}

@media screen and (max-width: 420px) {
  .partners > div > img {
    opacity: 50%;
  }

  .partners > p {
    font-size: 26px;
    position: absolute;
    left: 50vw;
    transform: translate(-50%, -50%);
  }
}
