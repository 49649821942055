.buttonGroup {
  @apply flex pl-4 border-t-[1.5px] border-transparent text-sm;

  @media screen and (max-width: 500px) {
    @apply pl-2;
  }

  button {
    @apply flex gap-1 pb-3 items-center border-b-[1.2px] border-transparent justify-end;

    &.chosen {
      border-bottom: 1.2px solid #5b0f8d;
      color: #5b0f8d;

      svg {
        filter: invert(21%) sepia(71%) saturate(2778%) hue-rotate(261deg) brightness(60%) contrast(91%);
      }
    }
  }
}



.grid {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  align-items: center;


  .downloadElement {
    @apply mt-2;
  }

  .editFilter {
    @apply ml-auto;
  }

  .resultsViews {
    grid-column: 1 / -1;
  }

  &.break {
    .downloadElement {
      >button {
        width: 100%;
      }
    }
  }


  @media screen and (max-width: 530px) {
    &.public {
      @apply mt-2;

      .downloadElement {
        @apply mt-0;
      }

      .editFilter {
        @apply bg-gray-100 pt-1 rounded-md;

        .buttonGroup {
          @apply px-2 gap-6;
        }

        button {
          @apply pb-1 w-auto;
          border-bottom: none;
        }
      }
    }
  }

  @media screen and (max-width: 910px) {
    &.admin {
      @apply mt-2;

      .downloadElement {
        @apply mt-0;
      }

      .editFilter {
        @apply bg-gray-100 pt-1 rounded-md;

        .buttonGroup {
          @apply px-2 gap-6;
        }

        button {
          @apply pb-1 w-auto;
          border-bottom: none;
        }
      }
    }
  }

  &.public {

    @media screen and (min-width: 530px) {

      .downloadElement {}

      .editFilter {
        grid-column: -2/-1;
        grid-row: -2/-1;
      }

      .resultsViews {
        grid-column: 1 / 2;
      }
    }

    @media screen and (min-width: 950px) {
      grid-template-columns: auto auto auto;
      grid-template-rows: 1fr;
      align-items: center;

      .downloadElement {
        grid-column: 2/3;
        grid-row: 1/2;
        margin: 0;
      }

      .editFilter {
        grid-column: -2/-1;
        grid-row: 1/2;
      }

      .resultsViews {
        grid-column: 1 / 2;
        grid-row: 1/2;
        margin-right: auto;
      }
    }
  }

  &.admin {

    @media screen and (min-width: 910px) {

      .downloadElement {}

      .editFilter {
        grid-column: -2/-1;
        grid-row: -2/-1;
      }

      .resultsViews {
        grid-column: 1 / 2;
      }
    }

    @media screen and (min-width: 1200px) {
      grid-template-columns: auto auto auto;
      grid-template-rows: 1fr;
      align-items: center;

      .downloadElement {
        grid-column: 2/3;
        grid-row: 1/2;
        margin: 0;
      }

      .editFilter {
        grid-column: -2/-1;
        grid-row: 1/2;
      }

      .resultsViews {
        grid-column: 1 / 2;
        grid-row: 1/2;
        margin-right: auto;
      }
    }
  }


}