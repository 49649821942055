/* .viewContainer {
  visibility: hidden;
}

.viewContainer.active {
  display: block;
  visibility: visible;
  opacity: 1;
}

.viewContainer.hidden {
  visibility: hidden;
  opacity: 0;
}
.viewContainer.tubeActive {
  visibility: visible;
  margin-left: 0vw;
}
.viewContainer.tubeHidden {
  visibility: visible;
  margin-left: -100vw;
} */

/* .viewContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
}

.viewContainer.active {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

.viewContainer.hidden {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-100%);
} */

.viewContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
}

.viewContainer.active {
  z-index: 2;
}

.viewContainer.hidden {
  z-index: 1;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

