.stampDutyPage {
    @apply text-gray-700;

    p {
        @apply text-base font-main-sans;
    }

    h2 {
        @apply mt-12 mb-2;
    }

    .specialH2 {

        @apply mt-2;
    }



    h3 {
        @apply mt-12 mb-3 font-semibold text-[#ffc600] text-2xl;

    }

    h4 {
        @apply mt-6 underline;
    }

    table {
        @apply text-base mb-6;

        tr {
            border-bottom-color: rgba(165, 165, 165, 0.1) !important;
        }
    }

    .aboutStampDuty {
        h3 {
            @apply text-main;
        }
    }
}