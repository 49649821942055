.development_small_container {
    @apply text-base;

    .info-dev-content {
        svg {
            height: 20px;
            width: 20px;
        }
    }
}

.info-dev-content {

    >div:first-of-type {
        @apply text-[1.5rem];
        foo: bar;
    }
}