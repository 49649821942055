.filters {
    @apply grid;
    row-gap: 1rem;
    column-gap: 1.5rem;

    .bedrooms {
        input {
            @apply bg-white;
        }
    }

    .price,
    .bedrooms {
        >p {
            @apply text-secondary-dark font-semibold;
        }
    }
}

@media screen and (max-width: 430px) {
    .filters {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 430px) and (max-width: 768px) {
    .filters {
        grid-template-columns: auto
    }
}

@media screen and (min-width: 768px) {
    .filters {
        grid-template-columns: repeat(2, auto);

        .search {
            grid-column: 1/-1;
        }
    }
}



.inputWithCornerLabel {
    @apply relative;

    >span {
        @apply absolute left-3 -top-[0.6rem] bg-white px-2 text-sm font-light text-gray-400;
    }

    input {
        @apply bg-white border-gray-300;
    }
}

.checkboxesContainer {
    @apply flex gap-1 w-full text-nowrap;

    label {
        @apply flex gap-2 items-center text-sm;

        input {
            @apply checkbox border border-gray-300;
        }
    }
}