/* #tubemapSVG #Station_Names text tspan {
  font-size: 30px !important;
} */
@import url("https://fonts.googleapis.com/css2?family=Mukta+Vaani:wght@400;500&display=swap");

                    .st2{fill:#f1f2f2}
                    .st4{fill:#fff}
                    .st15{fill:none;stroke:#fff;stroke-width:4.1668}
                    /* .st25{font-family:ArialMT} */
                    .st26{font-size:12px}
                    .st27{fill:#fff;stroke:#fff;stroke-width:4.1668}
                    .st28,.st29{fill:#fff;stroke:#d1d3d4;stroke-width:1.6277}
                    .st29{stroke-width:1.7512}
                    /* .st30{font-family:&apos;MuktaVaani-Medium&apos;} */
                    .st31{font-size:64.0421px}.st32{fill:#e6e7e8}
                    .st33{fill:#b1b3b6}
                    .st34{font-size:16.1694px}
                    .st35{font-size:19.1092px}
                    .st36{fill:none}
                    .st37{fill:#c7eafb}
                    .st38{fill:none;stroke:#00aeef;stroke-width:.6527}
                    .st40{fill:#00b3f0}
                    .st41{font-size:10.1389px}
                    .st42{fill:#1c3f94}
                    .st43{fill:none;stroke:#d385a9;stroke-width:5.6998;stroke-linejoin:round;stroke-miterlimit:3.994}
                    .st44{fill:#d385a9}
                    .st45{stroke-miterlimit:3.994;stroke-dasharray:8.8176,4.4088,0,8.8176,4.4088,0}
                    .st45,.st46,.st51{fill:none;stroke:#fff;stroke-width:2.0207;stroke-linejoin:round}
                    .st51{stroke:#d385a9;stroke-width:5.6988;stroke-miterlimit:3.994}
                    .st52,.st58{stroke-width:2.0204}
                    .st52{fill:none;stroke:#fff;stroke-linejoin:round}
                    .st58{stroke-miterlimit:3.994;stroke-dasharray:14.1732,8.5039}
                    .st58,.st65,.st66{fill:none;stroke:#fff;stroke-linejoin:round}
                    .st65{stroke-width:2.0207;stroke-miterlimit:3.994;stroke-dasharray:14.1732,8.5039}
                    .st66{stroke-width:2.02}.st72{fill:#ee3124}
                    .st75,.st76{fill:none;stroke:#7ac143;stroke-width:5.7091;stroke-linejoin:round;stroke-miterlimit:3.9938}
                    .st76{stroke:#fff;stroke-width:1.9592}
                    .st77{fill:#7ac143}
                    .st79,.st80{fill:none;stroke:#f58025;stroke-width:5.7012;stroke-linejoin:round;stroke-miterlimit:3.9938}
                    .st80{stroke:#fff;stroke-width:1.9558}
                    .st81{fill:#f58025}
                    .st82,.st83,.st84,.st85,.st89,.st90,.st91{fill:none;stroke:#634ea0;stroke-width:5.8789;stroke-linejoin:round;stroke-miterlimit:3.9938}
                    .st83,.st84,.st85,.st89,.st90,.st91{stroke:#fff;stroke-width:2.0167}
                    .st84,.st85,.st89,.st90,.st91{stroke:#634ea0;stroke-width:5.817;stroke-miterlimit:3.994}
                    .st85,.st89,.st90,.st91{stroke:#fff;stroke-width:1.996}.st89,.st90,.st91{stroke:#634ea0;stroke-width:5.818}.st90,.st91{stroke-width:5.7012;stroke-miterlimit:3.9938}.st91{stroke:#00b1b0}.st93{fill:#b06010}.st95{fill:none;stroke:#f1f2f2;stroke-width:7.4007;stroke-linejoin:round;stroke-miterlimit:3.9938}.st96{fill:#949ca1}.st100,.st102,.st99{fill:none;stroke:#009ddc;stroke-width:5.7012;stroke-linejoin:round;stroke-miterlimit:3.9938}.st100,.st102{stroke:#000}.st102{stroke:#1c3f94}.st103,.st104{fill:none;stroke:#f1f2f2;stroke-width:2.3277}.st104{stroke:#ee3124;stroke-width:5.7012;stroke-linejoin:round;stroke-miterlimit:3.9938}.st105{fill:#f386a1}.st107{fill:#ffd200}.st109{fill:#00853f}.st110{fill:none;stroke:#00853f;stroke-width:5.7012;stroke-linejoin:round;stroke-miterlimit:3.9938}.st113{fill:#97005e}.st114{fill:none;stroke:#97005e;stroke-width:5.7012;stroke-linejoin:round;stroke-miterlimit:3.9938}.st115{fill:#750d1d}.st116{fill:#dcddde}.st121{font-size:11.7595px}.st122{font-size:11.7685px}.st123{font-size:11.7594px}.st124{font-size:11.7599px}.st125{font-size:11.7626px}.st126{font-size:11.7621px}.st127{font-size:11.7563px}.st128{font-size:11.769px}.st129{font-size:11.7488px}.st131{font-size:11.7678px}.st132{font-size:11.7566px}.st133{font-size:11.7571px}.st134{font-size:11.7662px}.st135{font-size:11.7651px}.st136{font-size:11.7572px}.st137{font-size:12.2225px}.st140{fill:none;stroke:#26b1e6;stroke-width:1.85;stroke-linejoin:round}.st141{font-size:21.4018px}.st142,.st143{stroke-miterlimit:3.8636}.st142{fill:none;stroke:#79004c;stroke-width:2.4979;stroke-linejoin:round}.st143{fill:#fff;stroke:#d1d3d4;stroke-width:1.1501}.st144{font-size:13.38px}.st145{font-size:10.96px}.st147{font-size:13.9416px}.st148{font-size:13.9399px}.st151,.st152{fill:none;stroke:#00b3f0;stroke-width:.2835;stroke-linejoin:round}.st152{stroke-width:1.198}.st154{font-size:19.1748px}.st155{fill-rule:evenodd;clip-rule:evenodd;fill:#577197}.st156{font-size:9px}
                    .st158{fill:#577197}
                    /* .st161{font-family:&apos;STHeitiTC-Light&apos;} */
