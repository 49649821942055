.items {
    @apply flex gap-2 text-secondary-dark p-1;

    li {
        @apply w-full;

        p {
            @apply flex w-full text-nowrap gap-2 items-center px-3 py-[0.15rem] border border-transparent hover:bg-secondary-light/20 rounded-full;

            &.selected {
                @apply bg-secondary-light/30;
            }
        }


    }

    &.opened {
        @apply mt-2;

        p {
            @apply justify-center;
        }
    }
}